import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SelectBriefingLanguageComponent", {
        on: {
          handleChangeLanguage: function (e) {
            return (_vm.selectedLanguage = e)
          },
        },
      }),
      _c(
        VForm,
        {
          ref: "form",
          staticClass: "mt-5",
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
        },
        [
          _c(
            VExpansionPanels,
            [
              _c(
                VExpansionPanel,
                [
                  _c(VExpansionPanelHeader, [
                    _vm._v(" " + _vm._s(_vm.$t("company_presentation")) + " "),
                  ]),
                  _c(
                    VExpansionPanelContent,
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t("presentation_hint")))]),
                      _c("div", { staticClass: "mt-1 mb-4" }, [
                        _c("input", {
                          ref: "imageSlideInput",
                          attrs: {
                            id: "imageSlideInput",
                            name: "imageSlideInput",
                            type: "file",
                            multiple: "",
                            accept:
                              "zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed",
                          },
                          on: { change: _vm.setPresentationZip },
                        }),
                      ]),
                      _vm.company.Presentation != null
                        ? _c(VCheckbox, {
                            staticClass: "my-0 py-0",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { color: "var(--dark)" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("delete_presentation")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              651048522
                            ),
                            model: {
                              value: _vm.company.DeletePresentation,
                              callback: function ($$v) {
                                _vm.$set(_vm.company, "DeletePresentation", $$v)
                              },
                              expression: "company.DeletePresentation",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.roundBriefing
                ? _c(
                    VExpansionPanel,
                    { staticClass: "mt-1" },
                    [
                      _c(VExpansionPanelHeader, [
                        _vm._v(" " + _vm._s(_vm.$t("company_info")) + " "),
                      ]),
                      _c(
                        VExpansionPanelContent,
                        [
                          _c(VTextField, {
                            attrs: {
                              label: _vm.$t("funding_target"),
                              type: "number",
                              prefix: "R$",
                            },
                            on: {
                              change: function ($event) {
                                _vm.company.Target = parseFloat(
                                  _vm.company.Target
                                )
                              },
                            },
                            model: {
                              value: _vm.company.Target,
                              callback: function ($$v) {
                                _vm.$set(_vm.company, "Target", $$v)
                              },
                              expression: "company.Target",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VExpansionPanel,
                { staticClass: "mt-1" },
                [
                  _c(VExpansionPanelHeader, [
                    _vm._v(" " + _vm._s(_vm.$t("video_options")) + " "),
                  ]),
                  _c(
                    VExpansionPanelContent,
                    [
                      _c(VTextField, {
                        staticClass: "mt-0 pt-0",
                        attrs: {
                          type: "number",
                          label: _vm.$t("intro_video"),
                          hint: _vm.$tc("vimeo_hint", 1),
                        },
                        model: {
                          value: _vm.company.IntroVideo[_vm.selectedLanguage],
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.company.IntroVideo,
                              _vm.selectedLanguage,
                              $$v
                            )
                          },
                          expression: "company.IntroVideo[selectedLanguage]",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VExpansionPanel,
                { staticClass: "mt-1" },
                [
                  _c(VExpansionPanelHeader, [
                    _vm._v(" " + _vm._s(_vm.$t("overall")) + " "),
                  ]),
                  _c(
                    VExpansionPanelContent,
                    [
                      _c(VTextarea, {
                        attrs: {
                          "auto-grow": "",
                          outlined: "",
                          label: _vm.$t("description"),
                          rows: "3",
                        },
                        model: {
                          value: _vm.company.Description[_vm.selectedLanguage],
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.company.Description,
                              _vm.selectedLanguage,
                              $$v
                            )
                          },
                          expression: "company.Description[selectedLanguage]",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VExpansionPanel,
                { staticClass: "mt-1" },
                [
                  _c(VExpansionPanelHeader, [
                    _vm._v(" " + _vm._s(_vm.$t("photo_options")) + " "),
                  ]),
                  _c(VExpansionPanelContent, [
                    _c("div", { staticClass: "mt-2 mb-2" }, [
                      _c("span", [_vm._v(_vm._s(_vm.$t("hero")))]),
                      _c(
                        "div",
                        { staticClass: "mt-1" },
                        [
                          _c(
                            VRow,
                            { attrs: { align: "center" } },
                            [
                              _vm.company.Base64Hero != null
                                ? _c(VImg, {
                                    staticClass: "ml-3",
                                    attrs: {
                                      src: _vm.company.Base64Hero,
                                      "max-height": "5rem",
                                      "max-width": "5rem",
                                    },
                                  })
                                : _vm.company.Hero != null
                                ? _c(VImg, {
                                    staticClass: "ml-3",
                                    attrs: {
                                      src: _vm.gs.get_photo_path(
                                        _vm.company.Hero
                                      ),
                                      "max-height": "5rem",
                                      "max-width": "5rem",
                                    },
                                  })
                                : _vm._e(),
                              _c("input", {
                                ref: "imageHeroInput",
                                staticClass: "ml-4",
                                attrs: {
                                  id: "imageHeroInput",
                                  name: "imageHeroInput",
                                  type: "file",
                                  accept: "image/png, image/jpeg, image/bmp",
                                },
                                on: { change: _vm.setImageHero },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VCardActions,
            { staticClass: "pl-0 dxa_modal_actions" },
            [
              _c(
                VBtn,
                {
                  staticClass: "dxa_modal_btnSuccess",
                  attrs: {
                    color: "primary",
                    "min-width": "100",
                    loading: _vm.loading,
                    type: "submit",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("save")))]
              ),
              _c(
                VBtn,
                {
                  staticClass: "dxa_modal_btnError",
                  attrs: { color: "secondary", "min-width": "100" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("go_back")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }